import 'sanitize.css';
import 'flickity/css/flickity.css';
import 'sal.js/dist/sal.css';
import './style.scss';

import Flickity from 'flickity';
import SmoothScroll from 'smooth-scroll';
require('flickity-imagesloaded');
import sal from 'sal.js';

if(document.querySelector('.js-group__slider')) {
  const flktyGroupSlider = new Flickity( '.js-group__slider', {
    imagesLoaded: true,
    cellAlign: 'left',
    pageDots: false,
    autoPlay: 3000,
    wrapAround: true
  });
}

if(document.querySelector('.js-mainvisual__slider')) {
  const flktyMainvisualSlider = new Flickity( '.js-mainvisual__slider', {
    imagesLoaded: true,
    autoPlay: 6000,
    setGallerySize: false,
    wrapAround: true
  });
}

{
  document.querySelector('.js-fixedtrigger').addEventListener('click', () => {
    document.querySelector('body').classList.toggle('is-locked');
  });

  if(document.querySelector('.js-outline')) {
    const mediaQuery = matchMedia('(max-width: 767px)');

    const handle = (mq) => {
      const links = document.querySelectorAll('.js-outline .links a');
      const resetHoverEvent = () => {
        links.forEach((el, i) => {
          el.classList.remove('is-hover');
        });
      }
      const addHoverEventListener = (e) => {
        e.preventDefault();
        if(e.currentTarget.classList.contains('is-hover')) {
          if(e.currentTarget.getAttribute('href')) location.href = e.currentTarget.getAttribute('href');
        } else {
          resetHoverEvent();
          e.currentTarget.classList.add('is-hover');
        }
      }
      const addHoverEvent = () => {
        links.forEach((el, i) => {
          el.addEventListener('ontouchstart', addHoverEventListener);
          el.addEventListener('click', addHoverEventListener);
        });
      }
      addHoverEvent();
    }

    mediaQuery.addListener(handle);
  }
};


const setParamsToForm = () => {
  const params = (new URL(document.location)).searchParams;
  const inquiry = params.get('inquiry');
  if(!inquiry) {
    return;
  }
  if(inquiry == 'business' || inquiry == 'service' || inquiry == 'recruit') {
    const inquiryField = document.querySelector('select[name="your-inquiry"]');
    inquiryField.value = inquiry;
  }
}
setParamsToForm();
